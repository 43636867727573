<template>
    <div class="container">
        <h5 class="text-center  font-proxima-bold text-dark text-2">Subscription Plans</h5>
        <p class="text-center text-2 text-dark pb-4">Choose the plan that is best for you</p>
        <div class="row">
            <div class="col-lg-4 " v-for="(items,index) in item" :key=index>
                <PaymentCard :header="items.name" :subHeader="items.value" dateSuffix="per month">
                    <template #card-content><div class="px-4 text-2 pt-4 ">{{items.description}}</div></template>
                    <template v-slot:btn-content>
                        <div class="fl-x-cc   pb-4">
                            <btn color="eighth" design="basic-a" class="px-3 text-primary-600 font-weight-500"
                                 border-radius="1" @click="paymentRoute(items.id)">Continue
                            </btn>
                        </div>

                    </template>
                </PaymentCard>
            </div>

        </div>
    </div>
</template>

<script>
import PaymentCard from '@components/cards/PaymentCard';
import axios from 'secure-axios';
import urls from '@/data/urls';
import store from '@/store';

export default {
    name: 'UserSubscriptionPlans',
    components: { PaymentCard },
    data () {
        return {
            item: '',
            listUrl: urls.userHome.payment.list,
            recivedData: '',
            redirectUrl: '',
            recivedToken: ''
        };
    },
    mounted () {
        this.fetchData();
        this.getToken();
    },
    methods: {
        getToken () {
            const user = store.getters.currentUser;
            console.log('user', user);
            this.recivedToken = user.token;
        },
        async fetchData () {
            try {
                const response = await axios.get(this.listUrl);
                const result = response.data;
                this.item = result.data;
                console.log('detailItem', this.item);
                if (result.success === true) {
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
            }
        },
        paymentRoute (id) {
            this.redirectUrl = 'https://vetuatnew.xeoscript.com/payment/' + id + '/checkout/';
            /*  window.location.href = this.redirectUrl; */
            this.redirectToPaymentPage(this.recivedToken, this.redirectUrl);
        },
        redirectToPaymentPage (data, actionUrl) {
            // Create a form element
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = actionUrl;

            // Create a hidden input field for the data
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = 'token';
            input.value = data;

            // Append the input field to the form
            form.appendChild(input);

            // Append the form to the body
            document.body.appendChild(form);

            // Submit the form
            form.submit();

            // Remove the form from the body
            document.body.removeChild(form);
        }
    }

};
</script>

<style scoped>

</style>
