<template>
    <div class="card b-1 bs-2 p-0" >
        <div class="gradient-card b-1 br min-h-22">
            <div class="badge-slot">
                <slot name="badge"></slot>
            </div>
            <h5 class="pt-4 text-2 text-center text-dark font-proxima-bold ">{{ header }}</h5>
            <h6 class="text-7 pt-2 text-bold text-center text-fs-3"><span class="text-fs-2 mx-xl-n1 font-weight-400">₹</span>
                {{ subHeader }}  <span v-if="dateSuffix" class=" mx-xl-n1 text-fs-1 font-weight-400">/{{ dateSuffix }}</span>
            </h6>
            <div class="card-content min-h-11r">
                <slot name="card-content" ></slot>
            </div>
            <div class="btn-content">
                <slot name="btn-content"></slot>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'PaymentCard',
    props: {
        header: {
            type: String,
            required: true
        },
        subHeader: {
            type: String,
            required: true
        },
        dateSuffix: {
            type: String,
            default: ''
        }
    }
};
</script>

<style scoped>
.gradient-card {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(251, 253, 252, 1) 3%,
        rgba(243, 249, 249, 1) 18%,
        rgba(230, 241, 243, 1) 38%,
        rgba(213, 230, 235, 1) 57%,
        rgba(193, 220, 227, 1) 83%,
        rgba(173, 207, 217, 1) 97%);
}
.br{
    border-radius: 8px;
}
.min-h-22{
    min-height: 22rem;
}

.badge-slot {
    position: absolute;
    top: 10px;
    right: 10px;
}
</style>
