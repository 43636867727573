<template>
    <div class="container">
        <div class="row mt-4 ml-lg-9">
            <div class="col-lg-2 col-12 ml-lg-8">
                <div class="ml-3">
                    <p class="mb-lg-0 font-proxima-bold text-2">&copy; {{ new Date().getFullYear() }}</p>
                </div>
            </div>
            <div class="col-lg-3 col-12 mr-xl-n4">
                <div>
                    <a style="text-decoration: none" class="font-proxima-bold ml-3 text-seventh" @click="$router.push('/terms-and-conditions/')">Terms and conditions</a>
                </div>
            </div>
            <div class="col-lg-2 col-12 mr-xl-n4">
                <div>
                    <a style="text-decoration: none" class="font-proxima-bold ml-3 text-seventh" @click="$router.push('/privacy-policy/')">Privacy policy</a>
                </div>
            </div>
            <div class="col-lg-3 col-12 mr-xl-n5">
                <div>
                    <a style="text-decoration: none" class="font-proxima-bold ml-3 text-seventh" @click="$router.push('/cancellation-policy/')">Cancellation policy</a>
                </div>
            </div>
            <div class="col-lg-2 col-12 mr-xl-n5">
                <div>
                    <a style="text-decoration: none" class="font-proxima-bold ml-3 text-seventh" @click="$router.push('/refund-policy/')">Refund policy</a>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer'
};
</script>

<style scoped>
</style>
