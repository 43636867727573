<template>
    <div class="bg-2" id="top">
        <notifications/>
        <div class="bg-seventh pos-ft z-index-max">
            <div class="py-3 bg-seventh d-none d-lg-block">
                <div class="fl-x-cc logo-container">
                    <img
                        src="../assets/img/logo/logo-lg.png"
                        alt="Logo"
                        class="h-5vh logo-image img-fluid"
                    />
                    <p class="translate-x-40r mt-2 fl-x-cr mu-p" @click="LogoutAction">logout</p>
                </div>
            </div>
            <div class="d-block d-lg-none">
                <lego-nav-bar :items="menu" colored="true" class="nav-bar-home">
                    <template #logo>
                        <img src="../assets/img/logo/logo-lg.png" alt="Logo"/>
                    </template>
                </lego-nav-bar>
            </div>
        </div>

        <div class="px-lg-8 px-3 pt-7">
            <div class="row">
                <!-- Sidebar Section -->
                <div class="col-lg-4 d-none d-lg-block" v-if="showSidebar">
                    <div class="fl-y fl-a-e">
                        <menu-item
                            :class="{'mt-3': i > 0}"
                            v-for="(m, i) in menu"
                            :key="i"
                            :text="m.text"
                            :link="m.link"
                            :icon="m.icon"
                        ></menu-item>
                    </div>
                </div>
                <!-- Content Section -->
                <div :class="contentClass">
                    <div class="p-5 bg-2 pb-3 bg-1 min-h-80vh of-a max-h-80vh bs-5 round-2 b-1">
                        <transition name="fade" mode="out-in">
                            <router-view></router-view>
                        </transition>
                    </div>
                </div>
            </div>
        </div>

        <modal ref="UserSubscriptionModal" title=" " width="60r" show-additional-buttons>
            <template #buttons>
        <span @click="closeSubscriptionModal" class="mu-p">
          <i class="fa fa-close"></i></span
        >
            </template>
            <user-subscription-plans/>
        </modal>
<!--        <btn @click="$refs.UserSubscriptionModal.show()"
        >Open Subscription Plans ( test)
        </btn
        >-->
        <Footer/>
    </div>
</template>

<script>
import getMenu from '../data/user-home-menu';
import LegoNavBar from 'lego-framework/src/components/NavBar';
import urls from '../data/urls';
import axios from 'secure-axios';
import MenuItem from '../components/user-home/MenuItem';
import Footer from '../views/user-home/Footer';
import UserSubscriptionPlans from '../views/user-home/user-payment/UserSubscriptionPlans';

export default {
    name: 'UserHomeLayout',
    components: {
        UserSubscriptionPlans,
        Footer,
        MenuItem,
        LegoNavBar
    },
    data () {
        return {
            menu: getMenu(),
            value: '',
            logoutUrl: urls.auth.logout,
            brandsExcelDownloadUrl: urls.drugs.brandExcel.excel,
            excelDownloadLink: ''
        };
    },
    computed: {
        showSidebar () {
            const hiddenPaths = ['/terms-and-conditions/', '/privacy-policy/', '/cancellation-policy/', '/refund-policy/'];
            return !hiddenPaths.includes(this.$route.path);
        },
        contentClass () {
            return this.showSidebar ? 'col-lg-8' : 'col-lg-12';
        }
    },
    methods: {
        async linkEvent (name, event) {
            if (name === 'logout') {
                const response = await axios.post(this.logoutUrl).catch(() => {
                    this.serverError();
                });
                if (response.data.success) {
                    this.$router.push('/login');
                }
            }
        },
        closeSubscriptionModal () {
            this.$refs.UserSubscriptionModal.close();
        },
        async LogoutAction () {
            const that = this;
            const response = await axios.post(that.logoutUrl)
                .catch(function (exception) {
                    that.serverError();
                });
            console.log('resp', response.data.success);
            if (response.data.success) {
                this.$router.push('/login');
            }
        }
    }
};
</script>

<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .nav-bar-home {
        background-color: var(--color-seventh);
    }
.nav-bar-home {
    background-color: var(--color-seventh);
}

/* Hide the nav-bar on large screens */
.nav-bar-home {
    display: block;
}

@media (min-width: 992px) {
    .nav-bar-home {
        display: none;
    }
}
</style>
