import { render, staticRenderFns } from "./UserSubscriptionPlans.vue?vue&type=template&id=ba02ea8e&scoped=true&"
import script from "./UserSubscriptionPlans.vue?vue&type=script&lang=js&"
export * from "./UserSubscriptionPlans.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba02ea8e",
  null
  
)

export default component.exports