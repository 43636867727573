// import store from '../store';
import virusIcon from '../assets/user-home/virus.png';
import diagnoseIcon from '../assets/img/checkup.png';
import pharmacyIcon from '../assets/img/mix.png';
import favouritesIcon from '../assets/img/favorite.png';
import dictIcon from '../assets/img/dict.png';

const getMenu = function () {
    // const user = store.getters.currentUser;

    const menu = [
        {
            icon: virusIcon,
            text: 'Disease',
            link: '/animal-page/'
        },
        {
            icon: diagnoseIcon,
            text: 'Diagnose',
            link: '/diagnosis/list/'
        },
        {
            icon: pharmacyIcon,
            text: 'Pharmacy',
            link: '/pharmacy/list/'
        },
        {
            icon: favouritesIcon,
            text: 'Favourites',
            link: '/favourite/favourites/'
        },

        {
            icon: dictIcon,
            text: 'Dictionary',
            link: '/dictionary-list/'
        }
    ];
    return [
        ...menu
    ];
};
export default getMenu;
