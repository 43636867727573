<template>
    <li
        :class="['bs-5 round-2 p-4 list-unstyled w-15r mu-p', { active: isActive }]"
        @click="$router.push(link)">
        <a class="home-menu-tab fl-x fl-a-c text-decoration-none">
            <div class="hidden-md hidden-lg mob-home-tab">
                <img class="home-tab-menu-img icon-sm icon-lg icon-size-6" alt="#" :src="icon">
            </div>
            <span class="home-menu-tab-text home-tab-disease text-secondary ml-4">{{ text }}</span>
        </a>
    </li>
</template>

<script>
export default {
    name: 'MenuItem',
    props: {
        icon: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        link: {
            type: String,
            default: ''
        }
    },
    computed: {
        isActive () {
            return this.$route.path === this.link;
        }
    }
};
</script>

<style scoped>
.active {
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
}
</style>
